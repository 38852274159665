// Provides: stm.shared.models.metaVendor
// Requires: stm.shared, Backbone
// =====>>>>>

/**
 * Model for a Meta Vendor.
 *
 * @author Duc Tri Le
 */

/*jshint laxcomma:true */
(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm
		, shared = stm.shared
		, Backbone = window.Backbone
	;

	// ------------------------------------------------------------------------------------------ //

	var MetaVendorModel = Backbone.Model.extend({
		/**
		 * @param {Object=} attributes
		 * @param {Object=} options
		 * @constructor
		 */
		initialize: function(/* attributes, options */) {},

		// -------------------------------------------------------------------------------------- //
		// Backbone Magic
		// -------------------------------------------------------------------------------------- //

		/**
		 * @return {Object}
		 */
		defaults: function() {
			return {
				id: ""
				, currency: "$"
				, dataState: MetaVendorModel.STATE_UNKNOWN
				, displayName: ""
				, featured: false
				, logoUrl: ""
				, pricePerNight: 0
				, taAuthor: ""
				, taxFeesPerNight: 0
				, totalPrice: 0
				, url: ""
				, homePageUrl: ""
				, fauxTabs: ""

				// The following attributes can be changed dynamically by the script
				, order: 0
				, visibility: true
				, lowestPrice: false
			};
		},

		// -------------------------------------------------------------------------------------- //
		// Properties
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Setters and Getters
		// -------------------------------------------------------------------------------------- //

		/**
		 * @return {string}
		 */
		getId: function() {
			return this.get("id");
		},

		/**
		 * @param {string} id
		 * @param {Object=} options
		 * @return {this}
		 */
		setId: function(id, options) {
			return this.set("id", id, options);
		},

		/**
		 * @return {string}
		 */
		getCurrency: function() {
			return this.get("currency");
		},

		/**
		 * @param {string} currency
		 * @param {Object=} options
		 * @return {this}
		 */
		setCurrency: function(currency, options) {
			return this.set("currency", currency, options);
		},

		/**
		 * @return {string}
		 */
		getDataState: function() {
			return this.get("dataState");
		},

		/**
		 * @param {string} dataState
		 * @param {Object=} options
		 * @return {this}
		 */
		setDataState: function(dataState, options) {
			return this.set("dataState", dataState, options);
		},

		/**
		 * @return {string}
		 */
		getDisplayName: function() {
			return this.get("displayName");
		},

		/**
		 * @param {string} displayName
		 * @param {Object=} options
		 * @return {this}
		 */
		setDisplayName: function(displayName, options) {
			return this.set("displayName", displayName, options);
		},

		/**
		 * @return {boolean}
		 */
		isFeatured: function() {
			return this.get("featured");
		},

		/**
		 * @param {boolean} featured
		 * @param {Object=} options
		 * @return {this}
		 */
		setFeatured: function(featured, options) {
			return this.set("featured", featured, options);
		},

		/**
		 * @return {string}
		 */
		getLogoUrl: function() {
			return this.get("logoUrl");
		},

		/**
		 * @param {string} logoUrl
		 * @param {Object=} options
		 * @return {this}
		 */
		setLogoUrl: function(logoUrl, options) {
			return this.set("logoUrl", logoUrl, options);
		},

		/**
		 * @return {number}
		 */
		getPricePerNight: function() {
			return this.get("pricePerNight");
		},

		/**
		 * @param {number} pricePerNight
		 * @param {Object=} options
		 * @return {this}
		 */
		setPricePerNight: function(pricePerNight, options) {
			return this.set("pricePerNight", pricePerNight, options);
		},

		/**
		 * @return {string}
		 */
		getTAAuthor: function() {
			return this.get("taAuthor");
		},

		/**
		 * @param {string} taAuthor
		 * @param {Object=} options
		 * @return {this}
		 */
		setTAAuthor: function(taAuthor, options) {
			return this.set("taAuthor", taAuthor, options);
		},

		/**
		 * @return {number}
		 */
		getTaxFeesPerNight: function() {
			return this.get("taxFeesPerNight");
		},

		/**
		 * @param {number} taxFeesPerNight
		 * @param {Object=} options
		 * @return {this}
		 */
		setTaxFeesPerNight: function(taxFeesPerNight, options) {
			return this.set("taxFeesPerNight", taxFeesPerNight, options);
		},

		/**
		 * @return {number}
		 */
		getTotalPrice: function() {
			return this.get("totalPrice");
		},

		/**
		 * @param {number} totalPrice
		 * @param {Object=} options
		 * @return {this}
		 */
		setTotalPrice: function(totalPrice, options) {
			return this.set("totalPrice", totalPrice, options);
		},

		/**
		 * @return {string}
		 */
		getUrl: function() {
			return this.get("url");
		},

		/**
		 * @param {string} url
		 * @param {Object=} options
		 * @return {this}
		 */
		setUrl: function(url, options) {
			return this.set("url", url, options);
		},

		/**
		 * @return {string}
		 */
		getHomePageUrl: function() {
			return this.get("homePageUrl");
		},

		/**
		 * @param {string} homePageUrl
		 * @param {Object=} options
		 * @return {this}
		 */
		setHomePageUrl: function(homePageUrl, options) {
			return this.set("homePageUrl", homePageUrl, options);
		},

		/**
		 * @return {string}
		 */
		getFauxTabs: function() {
			return this.get("fauxTabs");
		},

		/**
		 * @param {string} fauxTabs
		 * @param {Object=} options
		 * @return {this}
		 */
		setFauxTabs: function(fauxTabs, options) {
			return this.set("fauxTabs", fauxTabs, options);
		},

		/**
		 * @return {number}
		 */
		getOrder: function() {
			return this.get("order");
		},

		/**
		 * @param {number} order
		 * @param {Object=} options
		 * @return {this}
		 */
		setOrder: function(order, options) {
			return this.set("order", order, options);
		},

		/**
		 * Is this vendor shown?
		 * @return {boolean}
		 */
		getVisibility: function() {
			return this.get("visibility");
		},

		/**
		 * Set whether or not this vendor has been shown
		 * @param {boolean} visibility
		 * @param {Object=} options
		 */
		setVisibility: function(visibility, options) {
			return this.set("visibility", visibility, options);
		},

		/**
		 * Does this vendor have the lowest price?
		 * @return {boolean}
		 */
		isLowestPrice: function() {
			return this.get("lowestPrice");
		},

		/**
		 * Set whether or not this vendor has the lowest price
		 * @param {boolean} lowestPrice
		 * @param {Object=} options
		 */
		setLowestPrice: function(lowestPrice, options) {
			return this.set("lowestPrice", lowestPrice, options);
		},

		// -------------------------------------------------------------------------------------- //
		// Event Handlers
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Methods
		// -------------------------------------------------------------------------------------- //

		/**
		 * Map the data of this model so that it can be used by stm.shared.models.tabBrowsingAd.
		 *
		 * @return {Object}
		 */
		mapToTabBrowsingAdModelAttributes: function() {
			return {
				id: this.getId()
				, name: this.getDisplayName()
				, currency: this.getCurrency()
				, price: this.getPricePerNight()
				, url: this.getUrl()
				, homePageUrl: this.getHomePageUrl()
				, fauxTabs: this.getFauxTabs()
				, position: this.getOrder()
				, selected: false
			};
		}
	}, {
		// -------------------------------------------------------------------------------------- //
		// Static
		// -------------------------------------------------------------------------------------- //

		/**
		 * @const
		 *		The state specifying there is availability.
		 */
		STATE_AVAILABLE: "available"

		/**
		 * @const
		 *		The state specifying the search is still in progress.
		 */
		, STATE_PENDING: "pending"

		/**
		 * @const
		 *		The state specifying there is no availability.
		 */
		, STATE_UNAVAILABLE: "unavailable"

		/**
		 * @const
		 *		The state specifying the availability is unknown.
		 */
		, STATE_UNKNOWN: "unknown"
	});

	// ------------------------------------------------------------------------------------------ //

	shared.ns("models.metaVendor", MetaVendorModel);
})(window);

// Provides: stm.shared.collections.metaVendors
// Requires: stm.shared, Backbone, Underscore
// Requires: stm.shared.models.metaVendor
// =====>>>>>

/**
 * Collections of Meta Vendors.
 *
 * @author Duc Tri Le
 */

/*jshint laxcomma:true */
(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm
		, shared = stm.shared
		, Backbone = window.Backbone
		, _ = window._
	;

	var MetaVendorModel = shared.models.metaVendor;

	// ------------------------------------------------------------------------------------------ //

	var MetaVendorsCollection = Backbone.Collection.extend({
		/**
		 * @param {Array.<MetaVendorModel>=} models
		 * @param {Object=} options
		 * @constructor
		 */
		initialize: function(/* models, options */) {
			this.attach();
		},

		// -------------------------------------------------------------------------------------- //
		// Backbone Magic
		// -------------------------------------------------------------------------------------- //

		/**
		 * @type {string}
		 */
		comparator: "order",

		/**
		 * @type {function}
		 */
		model: MetaVendorModel,

		// -------------------------------------------------------------------------------------- //
		// Properties
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Setters and Getters
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Event Handlers
		// -------------------------------------------------------------------------------------- //

		/**
		 * Event handler for when the order of a vendor ad changed.
		 *
		 * @param {MetaVendorModel} vendor
		 * @param {number} order
		 * @param {Object=} options
		 */
		onOrderChange: function(vendor, order/* , options */) {
			var vendorsWithOrder = this.where({ order: order })										/* {Array.<MetaVendorModel>} */
				, counter = 1																		/* {number} */
			;

			_.each(vendorsWithOrder, function(vendorWithOrder) {									/* {Window}: {MetaVendorModel} */
				if (vendorWithOrder.getId() !== vendor.getId()) {
					vendorWithOrder.setOrder(order + counter++);
				}
			});
		},

		// -------------------------------------------------------------------------------------- //
		// Methods
		// -------------------------------------------------------------------------------------- //

		/**
		 * Attach all necessary events.
		 *
		 * @return {this}
		 */
		attach: function() {
			this.listenTo(this, "change:order", this.onOrderChange);

			return this;
		}
	}, {
		// -------------------------------------------------------------------------------------- //
		// Static
		// -------------------------------------------------------------------------------------- //


	});

	// ------------------------------------------------------------------------------------------ //

	shared.ns("collections.metaVendors", MetaVendorsCollection);
})(window);

// Provides: stm.shared.models.metaHotel
// Requires: stm.shared, Backbone
// Requires: stm.shared.collections.metaVendors
// =====>>>>>

/**
 * Model a a Meta Hotel.
 *
 * @author Duc Tri Le
 */

/*jshint laxcomma:true */
(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm
		, shared = stm.shared
		, Backbone = window.Backbone
	;

	var MetaVendorsCollection = shared.collections.metaVendors;

	// ------------------------------------------------------------------------------------------ //

	var MetaHotelModel = Backbone.Model.extend({
		/**
		 * @param {Object=} attributes
		 * @param {Object=} options
		 * @constructor
		 */
		initialize: function(/* attributes, options */) {
			this.vendors = new this.vendorsCollectionClass();
		},

		// -------------------------------------------------------------------------------------- //
		// Backbone Magic
		// -------------------------------------------------------------------------------------- //

		/**
		 * @return {Object}
		 */
		defaults: function() {
			return {
				id: 0
				, availability: false
				, dataLoaded: false
			};
		},

		// -------------------------------------------------------------------------------------- //
		// Properties
		// -------------------------------------------------------------------------------------- //

		/**
		 * @type {MetaVendorsCollection}
		 *		The collection of vendors.
		 */
		vendors: null,

		/**
		 * @type {function}
		 *		Class to use to create the vendors collection.
		 */
		vendorsCollectionClass: MetaVendorsCollection,

		// -------------------------------------------------------------------------------------- //
		// Setters and Getters
		// -------------------------------------------------------------------------------------- //

		/**
		 * @return {number}
		 */
		getId: function() {
			return this.get("id");
		},

		/**
		 * @param {number} id
		 * @param {Object=} options
		 * @return {this}
		 */
		setId: function(id, options) {
			return this.set("id", id, options);
		},

		/**
		 * @return {boolean}
		 */
		hasAvailability: function() {
			return this.get("availability");
		},

		/**
		 * @param {boolean} availability
		 * @param {Object=} options
		 * @return {this}
		 */
		setAvailability: function(availability, options) {
			return this.set("availability", availability, options);
		},

		/**
		 * @return {boolean}
		 */
		isDataLoaded: function() {
			return this.get("dataLoaded");
		},

		/**
		 * @param {boolean} dataLoaded
		 * @param {Object=} options
		 * @return {this}
		 */
		setDataLoaded: function(dataLoaded, options) {
			return this.set("dataLoaded", dataLoaded, options);
		}

		// -------------------------------------------------------------------------------------- //
		// Event Handlers
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Methods
		// -------------------------------------------------------------------------------------- //


	}, {
		// -------------------------------------------------------------------------------------- //
		// Static
		// -------------------------------------------------------------------------------------- //


	});

	// ------------------------------------------------------------------------------------------ //

	shared.ns("models.metaHotel", MetaHotelModel);
})(window);

// Provides: stm.shared.collections.metaHotels
// Requires: stm.shared, Backbone
// Requires: stm.shared.models.metaHotel
// =====>>>>>

/**
 * Collections of Meta Hotels.
 *
 * @author Duc Tri Le
 */

/*jshint laxcomma:true */
(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm
		, shared = stm.shared
		, Backbone = window.Backbone
	;

	var MetaHotelModel = shared.models.metaHotel;

	// ------------------------------------------------------------------------------------------ //

	var MetaHotelsCollection = Backbone.Collection.extend({
		// -------------------------------------------------------------------------------------- //
		// Backbone Magic
		// -------------------------------------------------------------------------------------- //

		/**
		 * @type {function}
		 */
		model: MetaHotelModel

		// -------------------------------------------------------------------------------------- //
		// Properties
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Setters and Getters
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Event Handlers
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Methods
		// -------------------------------------------------------------------------------------- //


	}, {
		// -------------------------------------------------------------------------------------- //
		// Static
		// -------------------------------------------------------------------------------------- //


	});

	// ------------------------------------------------------------------------------------------ //

	shared.ns("collections.metaHotels", MetaHotelsCollection);
})(window);

// Provides: stm.shared.metaClient
// Requires: stm.shared, jQuery, Underscore
// Requires: stm.define
// Requires: stm.mixins.observable
// Requires: stm.shared.collections.metaHotels
// Requires: stm.shared.models.metaVendor
// Requires: stm.shared.soleChain
// =====>>>>>

/**
 * Client for retrieving the Meta Vendors.
 *
 *		var client = new MetaClient(123456);
 *		client.hotels.add({ id: 98765 });
 *		client.adults = 2;
 *		client.checkinDate = "07/18/2013";
 *		client.checkoutDate = "07/20/2013";
 *		client.siteId = 200;
 *
 *		client.subscribe(MetaClient.EVENT_LOADED, function(client) { ... });
 *		client.subscribe(MetaClient.EVENT_ERROR, function(client, response) { ... });
 *
 *		client.fetchUsingHotels();
 *
 * @global stm.pageData.queryString.icak
 * @global stm.pageData.queryString.icup
 */

/*jshint laxcomma:true */
(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm
		, shared = stm.shared
		, $j = window.jQuery
		, _ = window._
	;

	var define = stm.define
		, ObservableMixin = stm.mixins.observable
		, MetaHotelsCollection = shared.collections.metaHotels
		, MetaVendorModel = shared.models.metaVendor
		, SoleChain = shared.soleChain
	;

	var GICAK = function() { return stm.pageData.queryString ? stm.pageData.queryString.icak : null; };
	var GICUP = function() { return stm.pageData.queryString ? stm.pageData.queryString.icup : null; };

	// ------------------------------------------------------------------------------------------ //

	var MetaClient = define(null, ObservableMixin, {
		/**
		 * @param {number} mcid
		 *		Refer to the <mcid> property.
		 * @param {Object=} options
		 *		Refer to the <options> property.
		 * @constructor
		 */
		constructor: function(mcid, options) {
			this.mcid = mcid;
			this.options = _.extend({}, _.result(this, "options"), options);
			this.fetchChain = new SoleChain();

			// Some of the user options should be stored as properties of the instance
			this.url = this.options.url;

			if (this.options.city) {
				this.city = this.options.city;
				this.hotels = this.city.hotels;
			} else {
				this.hotels = this.options.hotels;
			}

			delete this.options.city;
			delete this.options.hotels;
			delete this.options.url;
		},

		// -------------------------------------------------------------------------------------- //
		// Properties
		// -------------------------------------------------------------------------------------- //

		/**
		 * @type {number}
		 *		The number of adults.
		 */
		adults: null,

		/**
		 * @type {string}
		 *		The checkin date in the format "mm/dd/yyyy".
		 */
		checkinDate: null,

		/**
		 * @type {string}
		 *		The checkout date in the format "mm/dd/yyyy".
		 */
		checkoutDate: null,

		/**
		 * @type {stm.shared.models.metaCity}
		 *		The city for which we will make the search for.
		 */
		city: null,

		/**
		 * @type {SoleChain}
		 *		The chain used for fetching the vendors.
		 */
		fetchChain: null,

		/**
		 * @type {MetaHotelsCollection}
		 *		The list of hotels for which we will make the search for.
		 */
		hotels: null,

		/**
		 * @type {number}
		 *		The MCID to use.
		 */
		mcid: null,

        /**
		 * @type {number}
		 * 		The site id of where the request is coming from.
         */
        siteId: 200,

		/**
		 * @return {Object}
		 *		After class has been instantiated, the result of this function will become the value
		 *		of this property.
		 */
		options: function() {
			return {
				// {number} - Maximum number of retries
				maxRetries: 3

				// {number - The number of milliseconds to delay each subsequent request
				, requestDelay: 2000

				// Following options will be removed from the options property and be stored as a
				// property on the instance

				// {MetaCityModel} - See <city> property
				, city: null

				// {MetaHotelsCollection - See <hotels> property
				, hotels: new MetaHotelsCollection()

				// {string} - See <url>
				, url: "/metapartnerlist"
			};
		},

		/**
		 * @type {string}
		 *		For each "fetch" call, the first request made requires us to send all the search
		 *		data. It will return this key which is used for each subsequent calls so that allows
		 *		TA to return back continued data rather than starting over.
		 */
		queryKey: null,

		/**
		 * @type {number}
		 *		The counter for how many requests has been made since "fetch" is called.
		 */
		requestCounter: null,

		/**
		 * @type {string}
		 *		Depending on whethe or not we are using using the city or the hotels, different data
		 *		is provided to the server, as such, this is used to keep track of what we are doing.
		 *		Refer to the "SEARCH_TYPE_*" constants for possible values.
		 */
		searchType: null,

		/**
		 * @type {string}
		 *		The URL to the API endpoint.
		 */
		url: null,

		// -------------------------------------------------------------------------------------- //
		// Setters and Getters
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Event Handlers
		// -------------------------------------------------------------------------------------- //



		// -------------------------------------------------------------------------------------- //
		// Methods
		// -------------------------------------------------------------------------------------- //

		/**
		 * Cleanup after the data has been fully loaded or we have reached the maximum number of
		 * requests.
		 *
		 * @param {stm.chain} chain
		 * @return {this}
		 */
		cleanupRequest: function(chain) {
			// We could have gotten here because the maximum number of retries have been reached in
			// which case we need to update the information for the hotel and vendor based on the
			// data that is currently present
			this.hotels.each(function(hotel) {
				var hasAvailability = false;														/* {boolean} */

				hotel.vendors.each(function(vendor) {												/* {Window}: {MetaVendorModel} */
					// Depending on the state of a vendor:
					//	- If it is still pending, mark it as unknown.
					//	- If it is available or unknown, flag that this hotel has availability.
					switch (vendor.getDataState()) {
						case MetaVendorModel.STATE_AVAILABLE:
							hasAvailability = true;
							break;
						case MetaVendorModel.STATE_PENDING:
							vendor.setDataState(MetaVendorModel.STATE_UNKNOWN);
							/* falls through */
						case MetaVendorModel.STATE_UNKNOWN:
							hasAvailability = true;
							break;
					}
				});

				hotel.setDataLoaded(true);
				hotel.setAvailability(hasAvailability);
				hotel.vendors.sort();
			});

			this.publish(MetaClient.EVENT_LOADED, this);

			chain.runNext();
			return this;
		},

		/**
		 * Retrieve data from the server. Do NOT call this method directly but rather use the
		 * various "fetchUsing*" methods as they'll set some information which this method needs.
		 *
		 * @return {this}
		 */
		fetch: function() {
			this.fetchChain.restart();

			this.fetchChain.chain.append(
				"prepare-for-request"
				, _.bind(this.prepareForRequest, this, this.fetchChain.chain)
			);
			this.fetchChain.chain.append(
				"make-request"
				, _.bind(this.makeRequest, this, this.fetchChain.chain)
			);
			this.fetchChain.chain.append(
				"cleanup-request"
				, _.bind(this.cleanupRequest, this, this.fetchChain.chain)
			);

			this.fetchChain.chain.run();
			return this;
		},

		/**
		 * Retrieve data from the server using the city model.
		 *
		 * @return {this}
		 */
		fetchUsingCity: function() {
			this.searchType = MetaClient.SEARCH_TYPE_CITY;

			return this.fetch();
		},

		/**
		 * Retrieve data from the server using the hotels collection.
		 *
		 * @return {this}
		 */
		fetchUsingHotels: function() {
			this.searchType = MetaClient.SEARCH_TYPE_HOTELS;

			return this.fetch();
		},

		/**
		 * Handles the response from the server.
		 *
		 * @param {stm.chain} chain
		 * @param {Object} response
		 * @return {this}
		 */
		handleServerResponse: function(chain, response) {
			var self = this																			/* {MetaClient} */
				, allLoaded = true																	/* {boolean} */
			;

			response = this.preprocessResponse(response);

			if (!response || response.errorCode || !response.hotels) {
				this.publish(MetaClient.EVENT_ERROR, self, response);
				return self;
			}

			if (response.queryKey) {
				this.queryKey = response.queryKey;
			}

			_.each(response.hotels, function(hotelData, hotelId) {									/* {Window}: {Object}, {number} */
				var hotel;																			/* {stm.shared.models.metaHotel} */

				self.hotels.add({ id: hotelId });
				hotel = self.hotels.get(hotelId);

				hotel.set({
					availability: hotelData.availability
					, dataLoaded: hotelData.dataLoaded
				});

				_.each(hotelData.vendors, function(vendor) {										/* {Window}: {Object} */
					vendor.currency = response.currencySymbol;
				});

				hotel.vendors.set(
					hotelData.vendors
					, { add: true, merge: true, remove: true }
				);

				allLoaded = allLoaded && hotelData.dataLoaded;
			});

			// If the search was made without dates, then make we are only allowed one request so
			// assume all the data is loaded
			if (this.checkinDate === "mm/dd/yy" || this.checkoutDate === "mm/dd/yy") {
				allLoaded = true;
			}

			if (!allLoaded) {
				self.publish(MetaClient.EVENT_DATA_RETRIEVED, self, response);
				_.delay(self.makeRequest.bind(self, chain), self.options.requestDelay);
				return self;
			}

			chain.runNext();
			return self;
		},

		/**
		 * Make a request to the server to retrieve data.
		 *
		 * @param {stm.chain} chain
		 * @return {this}
		 */
		makeRequest: function(chain) {
			var requestData																			/* {Object} */
				, dataMissingHotels = []															/* {Array<stm.shared.models.metaHotel>} */
				, icak, icup
			;

			if (++this.requestCounter > this.options.maxRetries) {
				chain.runNext();
				return this;
			}

			if (this.queryKey) {
				requestData = {
					qkey: this.queryKey
					, mcid: this.mcid
				};
			} else {
				requestData = {
					date1: this.checkinDate
					, date2: this.checkoutDate
					, mcid: this.mcid
					, travelers: this.adults
					, siteId: this.siteId
				};

				if (this.searchType === MetaClient.SEARCH_TYPE_CITY) {
					requestData.tid = this.city.getId();
				} else {
					dataMissingHotels = this.hotels.filter(function(hotel) {						/* {Window}: {stm.shared.models.metaHotel} */
						return !hotel.isDataLoaded();
					});

					requestData.hotels = _.map(dataMissingHotels, function(hotel) {					/* {Window}: {stm.shared.models.metaHotel} */
						return hotel.getId();
					}).join(",");
				}
			}

			// To help with testing different countries
			icup = GICUP();
			icak = GICAK();
			if (icup) { requestData.icup = icup; }
			if (icak) { requestData.icak = icak; }

			$j.ajax({ url: this.url, data: requestData })
				.always(_.bind(this.handleServerResponse, this, chain));

			return this;
		},

		/**
		 * Do any preparation before making a request to the server.
		 *
		 * @param {stm.chain} chain
		 * @return {this}
		 */
		prepareForRequest: function(chain) {
			this.queryKey = null;
			this.requestCounter = 0;

			chain.runNext();
			return this;
		},

		/**
		 * Preprocess the response so that it matches the format that is usable by client.
		 *
		 * @param {Object} response
		 * @return {Object}
		 */
		preprocessResponse: function(response) {
			return response;
		},

		// -------------------------------------------------------------------------------------- //
		// Static
		// -------------------------------------------------------------------------------------- //

		STATIC: {
			/**
			 * @const
			 *		The event that is triggered when valid data is retrieved back from the server.
			 *		Handler callbacks will receive following arguments:
			 *			{MetaClient}
			 *				The meta client that triggered the event.
			 *			{Object}
			 *				The JSON response object from the server.
			 */
			EVENT_DATA_RETRIEVED: "stm-shared-metaClient:dataRetrieved"

			/**
			 * @const
			 *		The event that is triggered when invalid data is retrieved back from the server.
			 *		Handler callbacks will receive following arguments:
			 *			{MetaClient}
			 *				The meta client that triggered the event.
			 *			{Object}
			 *				The JSON response object from the server.
			 */
			, EVENT_ERROR: "stm-shared-metaClient:error"

			/**
			 * @const
			 *		The event that is triggered when data is fully loaded or we have ran out of
			 *		retries. Handler callbacks will receive following arguments:
			 *			{MetaClient}
			 *				The meta client that triggered the event.
			 */
			, EVENT_LOADED: "stm-shared-metaClient:loaded"

			/**
			 * @const
			 *		The search type specifying we are searching using the city information.
			 */
			, SEARCH_TYPE_CITY: "city"

			/**
			 * @const
			 *		The search type specifying we are searching using the hotels information.
			 */
			, SEARCH_TYPE_HOTELS: "hotels"
		}
	});

	// ------------------------------------------------------------------------------------------ //

	shared.ns("metaClient", MetaClient);
})(window);

// TODO can we extract the necessary pieces out of this class?
// Provides: stm.shared.metaUtils
// Requires: stm.shared, JQuery, Underscore
// Requires: stm.Browser
// Requires: stm.popup
// =====>>>>>

/**
 * Utility functions for Meta.
 *
 * @author Duc Tri Le
 *
 * @global stm.pageData.metaTabBrowsingDomain
 * @global stm.pageData.metaTabBrowsingExtraArgs
 * @gloabl stm.Browser
 */

(function(window) {																					/* {Window}: {Window} */
	"use strict";

	// Everything shall be local
	var stm = window.stm;
	var shared = stm.shared;
	var $j = window.jQuery;
	var _ = window._;

	var Popup = stm.Popup;

	// ------------------------------------------------------------------------------------------ //

	var MetaUtils = {
		/**
		 * Open the Tab Browsing experience.
		 *
		 * @param {number} hotelId
		 * @param {string} checkinDate
		 * @param {string} checkoutDate
		 * @param {number} travelers
		 * @param {number=} selectedVendorId
		 * @param {number=} mcid
         * @param {boolean} tabBrowsingDisabled
		 * @return Metautils
		 */
		openTabBrowsingWindow: function(hotelId, checkinDate, checkoutDate, travelers, selectedVendorId, mcid, tabBrowsingDisabled) {
			/* {Object} */
			var params;

			/* {string} */
			var domain, url;

			/* {Popup} */
			var popup;

			params = {
				hotelId: hotelId
				, date1: checkinDate
				, date2: checkoutDate
				, travelers: travelers
			};

			if (selectedVendorId) {
				params.selectedVendorId = selectedVendorId;
			}

			if (mcid) {
				params.mcid = mcid;
			}

			if (tabBrowsingDisabled) {
				params.metaOpener = 'single';
			}

			if (stm.pageData.metaTabBrowsingExtraArgs) {
				_.extend(params, stm.pageData.metaTabBrowsingExtraArgs);
			}

			domain = stm.pageData.metaTabBrowsingDomain ? stm.pageData.metaTabBrowsingDomain : "";
			url = domain + "/tab-browsing/check-rates.html?" + $j.param(params);

			popup = new Popup(url, {
				name: "stmMetaTabBrowsing"
				, otherFeatures: { location: 1, toolbar: 1 }
			});

			popup.useOptimalDimensions()
				.tryPop();

			if (popup.popupWindow) {
				popup.popupWindow.focus();
			}

			return MetaUtils;
		},

		/**
		 * Open vendor link in new a window
		 *
		 * @param {stm.shared.models.metaVendor} vendor
		 *		The vendor to open.
		 * @param {Object=} options
		 *		Any additional options to specify.
		 * @return MetaUtils
		 */
		openVendorInNewWindow: function(vendor, options) {
			/* {Object} */
			var popupSettings = {
				name: 'STMMetaVendor' + vendor.getId()
				, otherFeatures: {
					resizable: 1
					, scrollbars: 1
				}
			};

			/* {Popup} */
			var popup;

			/* {String} */
			var vendorUrl;

			options = options || {};

			//For IE and FF, we want the navigation enabled
			if (stm.Browser.ie || stm.Browser.firefox) {
				popupSettings.otherFeatures.location = 1;
				popupSettings.otherFeatures.toolbar = 1;
				popupSettings.otherFeatures.menubar = 1;
			}

			/* For vendors which we open directly (eg. not in a Meta Tab Browsing window), pass the page name on for tracking. */
			if (!_.isUndefined(options.additionalUrlParams)) {
				vendorUrl = vendor.getUrl() + "&" + $j.param(options.additionalUrlParams);
			}

			popup = new Popup(vendorUrl, popupSettings);
			popup.useOptimalDimensions()
				.tryPop();

			return MetaUtils;
		},

		/**
		 * Checks if current browser can support showing vendor in tab browsing
		 * @param {string} fauxTabs
		 *		FauxTabs settings string for the ad
		 * @return {boolean}
		 */
		isTabBrowsingSupported: function(fauxTabs) {
			var browserName = stm.Browser.name.toLowerCase();
			
			if (browserName === "safari") {
				return false;
			}

			//check fauxTabs settings
			if (fauxTabs && fauxTabs.length > 0) {
				//possible value for fauxTabs is combination of one or more of "","always", "ie", "ipad"
				if(fauxTabs === "always") {
					return false;
				}

				if (browserName === "ie" && fauxTabs.indexOf("ie") > -1) {
					return false;
				}
			}

			return true;
		},

		/**
		 * Checks if cookie dropper required
		 * @return {boolean}
		 */
		isCookieDropperRequired: function() {
			var browserName = stm.Browser.name.toLowerCase();

			if (browserName === "ie") {
				return true;
			}

			return false;
		}

	};

	// ------------------------------------------------------------------------------------------ //

	shared.ns("metaUtils", MetaUtils);
})(window);

