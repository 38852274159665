(function() {

    /**
     * Library used to send timing data to the central stats service.
     * Primary usage: sending page performance data on page load.
     * Usage:
     *
     * This library should be loaded before all of your other js files
     * in the head of the page.
     *
     * example include with options set:
     * <script type="text/javascript"
     *   src="<path_to_js_file>/metrics.js"
     *   data-metrics  // required
     *   data-metrics-host="http://www.host.com
     *   data-metrics-site-name="awd"
     *   data-metrics-page-name="farelist"
     *   data-metrics-env="dev" // this can be dev, prod, release, etc
     *   data-metrics-track-performance-on-load="true" // true/false
     *   data-metrics-version="1.0"></script>
     *
     * You can also set the options in code:
     * i.e. Metrics.options.host = "new host url";
     *
     * Options:
     *  host: url to the metrics logging service [default: localhost]
     *  pageName: what page are we on (farelist, tabbrowsing, etc) [default: null]
     *  version: what version of the metrics api are we talking to [default: 1]
     */
    window.Metrics = {

        /**
         * Class to hold an instance of a timing
         *
         * @var {function}
         */
        Timing: function(key, value) {
            this.key = key;
            this.value = value;
        },

        /**
         * Delimiter used to separate key values
         *
         * @var {String}
         */
        KEY_DELIMITER: '.',

        /**
         * What timestamp (ms) should we use as the navigation start time?
         *
         * @var {number}
         */
        fallbackStartTime: new Date().getTime(),

        /**
         * initialize options array, will be populated in the init method
         *
         * @var {object}
         */
        options: {},

        /**
         * Option default values
         *
         * @var {object}
         */
        defaults: {
            host: null,
            timingEndPoint: 'trackTimings.gif',
            trackPerformanceOnLoad: false,
            pageName: null,
            version: 1
        },

        /**
         * Array of marks we are keeping tabs on
         * @var {object} key value pairs
         *   key: timingKey we want to track
         *   value: startTime in milliseconds
         */
        marks: {},

        /**
         * Whether or not the performanceTimings has been sent to the service.
         * The performance timings object only gets written once per page load
         * so we'll only ever want to send it once.
         *
         * @var {boolean}
         */
        hasSentPerformanceTimings: false,

        /**
         * Initialize the metrics library:
         *   + build the options object
         *   + set up the onload event if necessary
         */
        init: function() {
            // load options from data attributes
            this.extend(this.options, this.defaults, this.getAttributeOptions());

            if (this.options.trackPerformanceOnLoad) {
                var self = this;
                window.onload = function() {
                    self.trackPerformanceTimings();
                };
            }
        },

        /**
         * extend multiple objects into one.
         * @param obj {object} base object to store all of the attributes
         * @param ... {object} object to extend into base obj
         * @return {object}
         */
        extend: function(obj) {
            var sources = Array.prototype.slice.call(arguments, 1),
                source = null;

            for (var i = 0; i < sources.length; i++) {
                source = sources[i];
                if (source) {
                    for (var prop in source) {
                        obj[prop] = source[prop];
                    }
                }
            }
            return obj;
        },

        /**
         * Get the dom object for the script tage this source came from
         *
         * @returns {dom object}
         */
        getScriptTag: function() {
            var scripts = document.getElementsByTagName('script'),
                elem = null,
                length = scripts.length;

            for (var i = 0; i < length; i++) {
                elem = scripts[i];
                if (elem && elem.hasAttribute('data-metrics')) {
                    return elem;
                }
            }
            return null;
        },

        /**
         * Gets all of the options set in the script tag data attributes.
         * @returns {object}
         */
        getAttributeOptions: function() {
            // update so that we only get the script tag once
            var scriptTag = this.getScriptTag();

            // no script tag... no options :(
            if (!scriptTag) {
                return {};
            }

            var data = {},
                host = scriptTag.getAttribute('data-metrics-host'),
                trackPerformanceOnLoad = scriptTag.getAttribute('data-metrics-track-performance-on-load'),
                pageName = scriptTag.getAttribute('data-metrics-page-name'),
                version = scriptTag.getAttribute('data-metrics-version');

            if (host) {
                data.host = host;
            }

            if (trackPerformanceOnLoad) {
                data.trackPerformanceOnLoad = trackPerformanceOnLoad === 'true';
            }

            if (pageName) {
                data.pageName = pageName;
            }

            if (version) {
                data.version = version;
            }

            return data;
        },

        /**
         * Builds a key prefix based on the pageName
         *
         * i.e. pages.<page>
         *
         * @returns {string}
         */
        getKey: function() {
            var prefixes = [],
                opts = this.options;

            if (opts.pageName) {
                prefixes.push('pages');
                prefixes.push(opts.pageName);
            }

            return prefixes.join(Metrics.KEY_DELIMITER);
        },

        /**
         * Get the current time, in milliseconds
         *
         * @returns {number}
         */
        getCurrentTime: function() {
            return new Date().getTime();
        },

        /**
         * Starts a timer for a particular key.  This is useful if you need to set a starting point which you
         * will later want to track a timing duration.  To track a mark just call the trackMark method with the
         * same key and it will automatically log to the metrics service the duration between calling setMark and
         * trackMark.
         *
         * @param key {string} unique key of the timing objective
         */
        setMark: function(key) {
            this.marks[key] = this.getCurrentTime();
        },

        /**
         * Tracks the duration since calling setMark on a unique key.  If the unique key was never set then nothing will
         * happen.  This will also delete the mark from the list after tracking the duration.
         *
         * @param key {string} unique key of the timing objective
         */
        trackMark: function(key) {
            if (this.marks.hasOwnProperty(key)) {
                var startTime = this.marks[key],
                    duration = this.getCurrentTime() - startTime,
                    markTiming = new this.Timing(key, duration);

                // track the duration to the service
                this.trackTimings([markTiming]);

                // remove the mark from the system
                delete this.marks[key];
            }
        },

        /**
         * Track all inputted timings to the server.  This will automatically prefix all keys in the timing array with the
         * base key, generated in the getKey method.
         *
         * @param {Array<Timing>} timingArray - all of the timings we would like
         *   to send to the server
         */
        trackTimings: function(timingArray) {
            var host = this.options.host + '/' + this.options.timingEndPoint,
                keyBase = this.getKey(),
                img = new Image(),
                params = {
                    v: this.options.version,
                    c: this.getCurrentTime()
                };

            // add the timing objects to the param list
            var timing = null;
            for (var i = 0; i < timingArray.length; i++) {
                timing = timingArray[i];
                params[keyBase + '.' + timing.key] =  timing.value;
            }

            img.src = this.buildUrl(host, params);
        },

        /**
         * Build the metrics tracking url based on the inputted host and query parameters
         *
         * @param host {String} host url i.e. 'http://www.blah.com'
         * @param params {Object} query parameters key => value pairs
         * @returns {string} the constructed url
         */
        buildUrl: function(host, params) {
            var couples = [];
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    couples.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]));
                }
            }

            return host + '?' + couples.join('&');
        },

        /**
         * Get performance timings, will attempt to get navigation timings
         * if the browser supports it, else it will fallback to some default
         * timings
         *
         * @returns {Array<Timing>} performance timings
         */
        getTimings: function() {
            var modern = this.getTimingsModern();
            if (modern) {
                return modern;
            }

            return this.getTimingsFallback();
        },

        /**
         * Get performance timings based on the navigation performance numbers
         *
         * @returns {Array<Timing>} performance timings
         */
        getTimingsModern: function() {
            if (!window.performance) return null;

            // convert the timing data from timestamps to timings in ms
            var timings = window.performance.timing,
                start = timings.navigationStart,
                timingArray = [];

            for (var key in timings) {
                time = timings[key];

                if (typeof time === 'function') {
                    continue;
                }

                if (time) {
                    timingArray.push(new this.Timing(key, time - start));
                }
            }

            return timingArray;
        },

        /**
         * Default performance timings for when the browser doesn't
         * support navigation timings.
         *
         * @returns {Array<Timing>} performance timings
         */
        getTimingsFallback: function() {
            var timings = [],
                loadEventEnd = new this.Timing('fallbackLoadEventEnd',
                    this.getCurrentTime() - this.fallbackStartTime);

            // add all of the timing objects
            timings.push(loadEventEnd);

            // return all of the timings
            return timings;
        },

        /**
         * track performance timings in milliseconds to the metrics service.
         *
         * This will only fire the request once.
         */
        trackPerformanceTimings: function() {
            if (!this.hasSentPerformanceTimings) {
                // track the timings to the server
                this.trackTimings(this.getTimings());
                this.hasSentPerformanceTimings = true;
            }
        }
    };

    Metrics.init();
})();
